import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Form, Link, useNavigate } from "react-router-dom";
import { CustomIcon } from "../components/Common";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import Select from "react-select";
import { logDOM } from "@testing-library/react";

function Customers() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [searchedCustomers, setSearchedCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAcess, setIsLoadingAcess] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [allowedMenus, setAllowedMenus] = useState([]);
  const [error, setError] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [sucessMsg, setSucessMsg] = useState("");
  const [crmType, setCrmType] = useState({
    label: "All Customers",
    value: "all",
  });
  const handleClose = () => {
    setSelectedCustomer();
    setShow(false);
    setAllowedMenus([]);
  };
  const handleShow = (customer) => {
    setSelectedCustomer(customer);
    setShow(true);
    setAllowedMenus((pre) => {
      let arr = [];
      for (let i of customer?.ctMenus) {
        if (i?.isAllowed) {
          arr.push(i?.menu_id);
        }
      }
      return arr;
    });
  };

  function handleCheck(menu) {
    setAllowedMenus((pre) => {
      if (pre.includes(menu?.menu_id)) {
        let index = pre.indexOf(menu?.menu_id);
        pre.splice(index, 1);
      } else {
        pre.push(menu?.menu_id);
      }
      return pre;
    });
  }

  async function fetchCustomerList(value) {
    const token = localStorage.getItem("mtm_user_token");
    console.log(customersList.length,'customersList.length');
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmdashboard/getcustomers`,
        {
          page: currentPage,
          crmType:  value || crmType?.value,
        },
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        const newCustomers = response.data.customers.filter((newCust) => {
          return !customersList.some(
            (existingCust) => existingCust.customer_id === newCust.customer_id
          );
        });

        setIsLoading(false);
      value ? setCustomersList(response.data.customers) :  setCustomersList([...customersList, ...newCustomers]);
        setHasMore(response.data.hasMore);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function searchCustomer({e, value}) {
    e.preventDefault();
    setCurrentPage(0);
    setHasMore(false);
    setCustomersList([]);

    const token = localStorage.getItem("mtm_user_token");
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmdashboard/getcustomers`,
        {
          searchInput: searchInput,
          crmType: value || crmType?.value,
        },
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        setSearchedCustomers(response?.data?.customers);
      } else {
        setSearchedCustomers([]);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleMenuAccess() {
    const token = localStorage.getItem("mtm_user_token");
    setIsLoadingAcess(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmdashboard/customer/ct/access`,
        {
          menu_id: allowedMenus,
          customer_id: selectedCustomer?.customer_id,
        },
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        if (searchedCustomers.length) {
          setSearchedCustomers((pre) => {
            return pre.map((cust) => {
              if (cust.customer_id === selectedCustomer?.customer_id) {
                return {
                  ...selectedCustomer,
                  ctMenus: selectedCustomer?.ctMenus?.map((menu) => {
                    if (allowedMenus.includes(menu?.menu_id)) {
                      return { ...menu, isAllowed: true };
                    } else {
                      return { ...menu, isAllowed: false };
                    }
                  }),
                  hasCTAccess: allowedMenus.length > 0,
                };
              } else {
                return cust;
              }
            });
          });
        } else {
          setCustomersList((pre) => {
            return pre.map((cust) => {
              if (cust.customer_id === selectedCustomer?.customer_id) {
                return {
                  ...selectedCustomer,
                  ctMenus: selectedCustomer?.ctMenus?.map((menu) => {
                    if (allowedMenus.includes(menu?.menu_id)) {
                      return { ...menu, isAllowed: true };
                    } else {
                      return { ...menu, isAllowed: false };
                    }
                  }),
                  hasCTAccess: allowedMenus.length > 0,
                };
              } else {
                return cust;
              }
            });
          });
        }
        handleClose();
        setSucessMsg("Acess Granted Successfully");
        setShowSuccessModal(true);
        setTimeout(() => {
          setSucessMsg("");
          setShowSuccessModal(false);
        }, 2000);
      } else {
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    } finally {
      setIsLoadingAcess(false);
    }
  }

  // Function to handle scroll and fetch more data
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      hasMore
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [hasMore]);

  function mapCustomers(array) {
    return array.map((cust, index) => (
      <tr key={cust?.organization_name + cust?.customer_id}>
        <td>{index + 1}</td>
        <td>{cust?.customer_id}</td>
        <td>{cust?.organization_name}</td>
        <td>{cust?.customer_name}</td>
        <td>{cust?.customer_email}</td>
        <td>
         {cust?.customer_crm_type == 4 && <div className="form-check form-switch d-flex justify-content-center">
            <input
              className="form-check-input"
              checked={cust?.hasCTAccess}
              type="checkbox"
              role="switch"
              id="flexSwitch1"
              onClick={() => handleShow(cust)}
            />
          </div>}
        </td>
        <td>
         {cust?.hasCTAccess && <div className="d-flex align-items-center">
            <p>View</p>
            <button
              type="button"
              className="arrow-btn mx-2"
              onClick={() =>
                navigate(
                  `/dashboard-chart/${cust?.customer_id}/${
                    cust?.organization_name || "Organization Name"
                  }`
                )
              }
            >
              <svg className="icon fs-24">
                <use href="#icon_arrow"></use>
              </svg>
            </button>
          </div>}
        </td>
      </tr>
    ));
  }

  useEffect(() => {
    fetchCustomerList();
  }, [currentPage]);
  useEffect(() => {
    if (!searchInput) {
      setHasMore(false);
      setCurrentPage(0);
      setSearchedCustomers([]);
      fetchCustomerList();
    }
  }, [searchInput]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  return (
    <>
      {isLoading && (
        <div
          style={{
            width: "80vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: 100,
          }}
        >
          <Loader />
        </div>
      )}
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">Customers</h2>
            <div className="d-flex py-4  align-items-center">
              <form onSubmit={(e)=>searchCustomer({e})} className="d-flex mt-2">
                <input
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  type="search"
                  className="form-control rounded-pill  d-block"
                  style={{ width: "400px", alignSelf: "start" }}
                  placeholder="Search Company Name or Company Id"
                  id="datatable-search-input"
                />
                <button
                  type="submit"
                  className="grd-btn mx-2"
                  disabled={!searchInput}
                >
                  <CustomIcon name="icon_search" className="fs-5" />
                </button>
              </form>
              <Select
              className="mt-2 mx-4"
                value={crmType}
                onChange={(option) => {
                  setCrmType(option);
                  setHasMore(false);
                  setCurrentPage(0);
                  setSearchedCustomers([]);
                  setCustomersList([]);
                  if(searchInput){
                    searchCustomer({e: {preventDefault: () => {}},value:option?.value});
                  }else{
                    fetchCustomerList(option?.value);
                  }
                }}
                placeholder="Select CRM Type"
                options={[
                  { label: "All Customers", value: "all" },
                  { label: "Qualifying", value: 0 },
                  { label: "Opportunities", value: 3 },
                  { label: "Current", value: 4 },
                  { label: "Disqualified", value: 2 },
                  { label: "Blacklisted", value: 1 },
                ]}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th style={{ padding: "12px 0" }}>Serial Number</th>
                <th>Company Id</th>
                <th>Company Name</th>
                <th>Name</th>
                <th>Email/Username</th>
                <th>Enable To CT</th>
                <th></th>
              </tr>
              {searchedCustomers.length
                ? mapCustomers(searchedCustomers)
                : mapCustomers(customersList)}
            </table>
            {searchedCustomers.length == 0 &&
            customersList.length == 0 &&
            !isLoading ? (
              <h2 className="  text-center">No customer found!</h2>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
      {/*  Modal  */}
      <Modal size="" show={show} onHide={handleClose} centered animation={true}>
        <Modal.Header className="border-0 " closeButton></Modal.Header>
        <Modal.Body className="pt-0">
          <h5 className="fw-600 fs-20 text-center">
            Choose allowed features for{" "}
          </h5>
          <h5 className="fw-600 fs-20 text-center ">
            {selectedCustomer?.organization_name}{" "}
          </h5>
          <div className="d-flex justify-content-center mt-3">
            <div>
              {selectedCustomer?.ctMenus?.map((menu) => (
                <div
                  key={menu?.menu_id + selectedCustomer?.customer_id}
                  className="form-check mb-3"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={menu?.isAllowed}
                    onChange={() => handleCheck(menu)}
                    value=""
                    id={
                      "flexCheck" +
                      menu?.menu_id +
                      selectedCustomer?.customer_id
                    }
                  />
                  <label
                    className="form-check-label"
                    for={
                      "flexCheck" +
                      menu?.menu_id +
                      selectedCustomer?.customer_id
                    }
                  >
                    {menu?.menu_name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center pb-3">
            <button
              onClick={handleClose}
              typeof="button"
              className="normal-btn px-4 py-2 rounded-pill me-4"
            >
              Cancel
            </button>
            <button
              disabled={isLoadingAcess}
              onClick={handleMenuAccess}
              typeof="button"
              className="normal-btn px-4 py-2 rounded-pill me-4"
            >
              {isLoadingAcess ? <Loader /> : "Grant Access"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        handleCloseSuccess={() => setShowSuccessModal(false)}
        showSuccess={showSuccessModal}
        successMessagePopup={sucessMsg}
      />
    </>
  );
}

export default Customers;
