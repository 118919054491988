import axios from "axios";
import moment from "moment";

export const getFormattedDate = (item) => {
    const currentDate = item["$d"];
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
  
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  
  export const getDashboardData = async (
    selectedDate,
    navigate,customer_id,
    {
      setDashboardRawDataMap,
      setDashboardRawData,
      setStatusPieList,
      setIsLoading,
      setShowErrModal,
      setError,setStatuses,setContainerTypeList,setCountryContainerList,setShippingLineContainerList
    },
    selectedContainerType
  ) => {
    try {
      let token = localStorage.getItem("mtm_user_token");
      setIsLoading(true);
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmdashboard/chartdata`,
        method: "POST",
        data: {
          from_date: selectedDate[0],
          to_date: selectedDate[1],
          container_type:selectedContainerType?.value || null,
          customer_id: customer_id
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });
  
      if (!response.data.error) {
        setDashboardRawDataMap(response.data?.booking_container_map);
        setDashboardRawData(response.data?.booking_container);
        setStatusPieList(response.data?.status_container);
        setStatuses(response.data?.booking_status);
        setContainerTypeList(response.data?.container_type);
        setCountryContainerList(response.data?.country_container);
        setShippingLineContainerList(response.data?.shipping_line_container);
      } else {
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };
export function getStatusIcon(status,size) {
    switch (status) {
      case "Booking confirmed":
        return<div className={`bg-lightpurple ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
        <div className="w30px h30px bg-purple rounded-2 d-flex align-items-center justify-content-center me-2">
          <svg className="icon fs-22 text-white">
          <use href="#icon_origin"></use>
          </svg>
        </div>
        <span className="fw-600 text-purple">
          {status}
        </span>
      </div> 
    
      case "At port of loading":
        return <div className={`bg-lightcyan ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
        <div className="w30px h30px bg-cyan rounded-2 d-flex align-items-center justify-content-center me-2">
          <svg className="icon fs-22 text-white">
          <use href="#icon_portloding"></use>
          </svg>
        </div>
        <span className="fw-600 text-cyan">
          {status}
        </span>
      </div> 
    
      case "Ocean in transit":
        return <div className={`bg-lightblue ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
        <div className="w30px h30px bg-blue rounded-2 d-flex align-items-center justify-content-center me-2">
          <svg className="icon fs-22 text-white">
          <use href="#icon_transit"></use>
          </svg>
        </div>
        <span className="fw-600 text-blue">
          {status}
        </span>
      </div>
    
      case "Near arrival":
        return <div className={`bg-lightpink ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
        <div className="w30px h30px bg-pink rounded-2 d-flex align-items-center justify-content-center me-2">
          <svg className="icon fs-22 text-white">
          <use href="#icon_portdischarge"></use>
          </svg>
        </div>
        <span className="fw-600 text-pink">
          {status}
        </span>
      </div>
    
      case "At port of destination":
        return <div className={`bg-lightpink ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
        <div className="w30px h30px bg-pink rounded-2 d-flex align-items-center justify-content-center me-2">
          <svg className="icon fs-22 text-white">
          <use href="#icon_portdischarge"></use>
          </svg>
        </div>
        <span className="fw-600 text-pink">
          {status}
        </span>
      </div>
    
      case "On route to warehouse":
        return <div className={`bg-lightyellow ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
        <div className="w30px h30px bg-yellow rounded-2 d-flex align-items-center justify-content-center me-2">
          <svg className="icon fs-22 text-white">
          <use href="#icon_house"></use>
          </svg>
        </div>
        <span className="fw-600 text-yellow">
          {status}
        </span>
      </div>
    
      case "At warehouse":
        return <div className={`bg-lightyellow ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
        <div className="w30px h30px bg-yellow rounded-2 d-flex align-items-center justify-content-center me-2">
          <svg className="icon fs-22 text-white">
          <use href="#icon_house"></use>
          </svg>
        </div>
        <span className="fw-600 text-yellow">
          {status}
        </span>
      </div>
    
      case "Delivered":
        return <div className={`bg-lightgreen ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
        <div className="w30px h30px bg-green rounded-2 d-flex align-items-center justify-content-center me-2">
          <svg className="icon fs-22 text-white">
          <use href="#icon_delivered"></use>
          </svg>
        </div>
        <span className="fw-600 text-green">
          {status}
        </span>
      </div>
    
      default:
        return <div className={`bg-lightpink ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
        <div className="w30px h30px bg-pink rounded-2 d-flex align-items-center justify-content-center me-2">
          <svg className="icon fs-22 text-white">
          <use href="#icon_origin"></use>
          </svg>
        </div>
        <span className="fw-600 text-pink">
          {status}
        </span>
      </div>;
       
    }
  }
export async function fetchMembersList(
  type,
  setMembers,
  setIsLoading,
  setError,
  setShowError,navigate
) {
  const token = localStorage.getItem("mtm_user_token");
  setIsLoading(true);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/mtmdashboard/getmembers/${type}`,
      {
        headers: {
          jwt_token: token,
        },
      }
    );

    if (!response?.data?.error) {
      setMembers(response?.data?.members);
    } else {
      setMembers([]);
      setError({ title: "Error", err: response?.data?.message });
      setShowError(true);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
  } finally {
    setIsLoading(false);
  }
}

export function mapMembers(array) {
    return array.map((member,index) =>  <tr key={member?.user_full_name + member?.id}>
    <td>{index + 1}</td>
    <td>{member?.user_full_name}</td>
    <td>{member?.email}</td>
    <td>
      <p>{member?.last_login_datetime ? moment(member?.last_login_datetime).format('MMMM D, YYYY') : "" }</p>
      <p>{member?.last_login_datetime ? moment(member?.last_login_datetime).format('h:mm A') : ""}</p>
    </td>
    {/* <td>
      <div className='d-flex align-items-center'>
        <p>View</p>
        <button type='button' className='arrow-btn mx-auto'>
          <svg className="icon fs-24">
            <use href="#icon_arrow"></use>
          </svg>
        </button>
      </div>
    </td> */}
  </tr>)
  }

  export function handleMemberSearch(members,searchInput,setSearchedMembers) {
    if(!searchInput){
        setSearchedMembers([])
        return
    }
    const filteredMembers = members.filter((member) => {
        return (
            member.user_full_name.toLowerCase().includes(searchInput.toLowerCase())
        )
    })
    setSearchedMembers(filteredMembers)
  }


  export async function handleLogOut(setIsLoading,navigate) {
    setIsLoading(true);
    try {
      const email = JSON.parse(localStorage.getItem("mtm_user_data"))?.email;
      const data = {
        email: email,
        app_type: 'Admin',
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmuser/logout`,
        data
      );
      if (response.status == 200) {
        setIsLoading(false);
        if (response.data.success) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/login");
        }
      } else {
        setIsLoading(false);
        alert("Error",response.data.msg);
        console.log("Error", response.data.msg);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error", error);
    }
  }