import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ErrorModal from "../components/ErrorModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalSalesMembers, setTotalSalesMembers] = useState();
  const [totalPricingMembers, setTotalPricingMembers] = useState();
  const [totalOperationsMembers, setTotalOperationsMembers] = useState();
  const [totalCustomers, setTotalCustomers] = useState();
  const [totalContainersInTransit, setTotalContainersInTransit] = useState();
  const [totalContainersDelivered, setTotalContainersDelivered] = useState();
  const [error, setError] = useState();
  const [showErrModal, setShowErrModal] = useState(false);


  const navigate = useNavigate()

  async function fetchDashboardCounts() {
    const token = localStorage.getItem("mtm_user_token");
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/mtmdashboard/getcounts`,
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        setTotalSalesMembers(response.data?.totalSalesMembers);
        setTotalPricingMembers(response.data?.totalPricingMembers);
        setTotalOperationsMembers(response.data?.totalOperationsMembers);
        setTotalCustomers(response.data?.totalCustomers);
        setTotalContainersInTransit(response.data?.totalContainersInTransit);
        setTotalContainersDelivered(response.data?.totalContainersDelivered);
      } else {
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchDashboardCounts();
  },[])
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 py-4">Dashboard</h2>
          </Col>
        </Row>
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-3 g-3 pb-4">
          <div className="col">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_sales"></use>
                    </svg>
                  </div>
                  <p>Total Sales Members</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">{totalSalesMembers}&nbsp;</h4>
              </div>
            </div>
          </div>
          <div className="col">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_pricetag"></use>
                    </svg>
                  </div>
                  <p>Total Pricing Members</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">{totalPricingMembers}&nbsp;</h4>
              </div>
            </div>
          </div>
          <div className="col">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_setting"></use>
                    </svg>
                  </div>
                  <p>Total Operations Members</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">{totalOperationsMembers}&nbsp;</h4>
              </div>
            </div>
          </div>
          <div className="col">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_customer"></use>
                    </svg>
                  </div>
                  <p>Total Customers</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">{totalCustomers}&nbsp;</h4>
              </div>
            </div>
          </div>
          <div className="col">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_container"></use>
                    </svg>
                  </div>
                  <p>Total Containers In-Transit</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">{totalContainersInTransit}&nbsp;</h4>
              </div>
            </div>
          </div>
          <div className="col">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_container"></use>
                    </svg>
                  </div>
                  <p>Total Containers Delivered</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">{totalContainersDelivered}&nbsp;</h4>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default Dashboard;
