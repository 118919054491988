import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Login from "../pages/Login";
import LayoutOutlet from "./LayoutOutlet";
import CompanySettings from "../pages/Dashboard";
import Dashboard from "../pages/Dashboard";
import DashboardChart from "../pages/DashboardChart";
import Customers from "../pages/Customers";
import Profile from "../pages/Profile";
import Security from "../pages/Security";
import MembersPage from "../pages/MembersPage";

function Routing() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<LayoutOutlet />}>
            <Route index element={<Dashboard />}></Route>
            <Route path="/dashboard-chart/:customer_id/:organization_name" element={<DashboardChart />} />
            <Route path="/operations" element={<MembersPage memberType={'Operations'} />} />
            <Route path="/sales" element={<MembersPage memberType={'Sales'} />} />
            <Route path="/pricing" element={<MembersPage memberType={'Pricing'} />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/security" element={<Security />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;
