import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { Form, Link, useNavigate } from "react-router-dom";
import ErrorModal from "../components/ErrorModal";
import { fetchMembersList, handleMemberSearch, mapMembers } from "../utils/commonFn";
import Loader from "../components/Loader";
import axios from "axios";
import validator from "validator";
import CryptoJS from "crypto-js";
import { SuccessModal } from "../components/SuccessModal";
function MembersPage({memberType}) {
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [searchedmembers, setSearchedMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [sucessMsg, setSucessMsg] = useState("")
  const [searchInput, setSearchInput] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [isLoadingAdding, setIsLoadingAdding] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [addNewUserDetails, setaddNewUserDetails] = useState({
    name:"",
    email:"",
    phone:"",
    password:""
  })
  const [addNewUserErrors, setaddNewUserErrors] = useState({
    name:false,
    email:false,
    phone:false,
    password:false
  })

  const [addmore, setaddmore] = useState("addmore")

  const [memberdata, setmemberdata] = useState([])

  function smallCheckFnForUser() {
    let name =
      addNewUserDetails?.name?.trim() == "" ? true : false;
    let email =
      !validator.isEmail(addNewUserDetails?.email);
    let phone =
      addNewUserDetails?.phone?.trim() == "" ? true : false;
    let password =
      !validator.isStrongPassword(addNewUserDetails?.password,{minLength:5,minUppercase:1,minSymbols:1,minNumbers:0})
   console.log(addNewUserDetails?.password,validator.isStrongPassword(addNewUserDetails?.password,{minLength:5,minUppercase:1,minSymbols:1,minNumbers:0}));
    if (name || email || phone || password) {
      setaddNewUserErrors({
        name,
        email,
        phone,
        password,
      });
      return true;
    } else {
      return false;
    }
  }

  function handleCloseAddUserModal() {
    setShowPassword(false)
    setShowAddUserModal(false)
    setaddNewUserDetails({
      name:"",
      email:"",
      phone:"",
      password:""
    })
    setaddNewUserErrors({
      name:false,
      email:false,
      phone:false,
      password:false
    })
  }
  async function handleAddUser(e) {
    e.preventDefault();
    if (addmore === "addmore") {
      if (smallCheckFnForUser()) {
        return;
      }
    }
    setIsLoadingAdding(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      const cipherText = CryptoJS.AES.encrypt(
        addNewUserDetails.password,
        process.env.REACT_APP_SECRET
      );
      let data = {};
      if (addmore !== "addmore") {
        let selectedMember = memberdata?.find((item) => item?.id == addmore);
        data = {
          id: addmore,
          user_type: memberType,
          name: selectedMember?.user_full_name,
          email: selectedMember?.email,
          phone: selectedMember?.phone,
          password: "",
        };
      } else {
        data = {
          id: "",
          user_type: memberType,
          name: addNewUserDetails?.name,
          email: addNewUserDetails?.email,
          phone: addNewUserDetails?.phone,
          password: cipherText.toString(),
        };
      }
      console.log(data, "data");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmdashboard/employee/add`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (!response.data.error) {
        setSucessMsg("Account added Successfully");
        setShowSuccessModal(true);
        setSearchInput("");
        setTimeout(() => {
          setSucessMsg("");
          setShowSuccessModal(false);
        }, 2000);
        fetchMembersList(
          memberType,
          setMembers,
          setIsLoading,
          setError,
          setShowErrModal,
          navigate
        );
      } else {
        setError({ title: "Error", err: response.data.message });
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      setError("Error: " + error);
      setShowErrModal(true);
    } finally {
      setIsLoadingAdding(false);
      handleCloseAddUserModal();
    }
  }
  const handleAddNewUserChange = (e) => {
    setaddNewUserDetails({
      ...addNewUserDetails,
      [e.target.id]: e.target.value,
    });
    setaddNewUserErrors({
      ...addNewUserErrors,
      [e.target.id]: false,
    });
  };
  useEffect(() => {
    setSearchInput("")
    fetchMembersList(
      memberType,
      setMembers,
      setIsLoading,
      setError,
      setShowErrModal,
      navigate
    );
  
  }, [memberType]);

  const getDropdownData = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmdashboard/getmembers/otherapp_list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          "user_type":memberType?.toLowerCase()
        },
      });
      // console.log(response, "response");
      if (!response.data.error) {
        console.log(response, "response...");
        // let set=new Set();
        let array=[];

        for (let i = 0; i < members.length; i++) {
          const element = members[i];
          // set.add(element?.user_full_name?.trim())
          if(!array.includes(element?.user_full_name?.trim())){
            array.push(element?.user_full_name?.trim())
          }
        }
        console.log(array,"array set");
        let data=response.data?.members?.filter((item)=>!array.includes(item?.user_full_name?.trim()));
        setmemberdata(data)
      }
    } catch (error) {
    }
  };
  console.log(memberdata,"members...")

  useEffect(() => {
    if(members&&members?.length>0){
      setmemberdata([]);
      getDropdownData()
    }
  }, [memberType,members])
  
  console.log(memberdata)

  return (
    <>
    {isLoading && (
          <div
            style={{
              width: "80vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              zIndex: 100,
            }}
          >
            <Loader />
          </div>
        )}
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">{memberType}</h2>
            <div className="d-flex py-4">
              <div>
                <input
                  type="search"
                  class="form-control"
                  id="searchinput"
                  placeholder="Search Employee Name"
                  value={searchInput}
                  disabled={!members.length}
                  onChange={e=> {setSearchInput(e.target.value); handleMemberSearch(members, e.target.value, setSearchedMembers) }}
                />
              </div>
              <div className="ms-auto">
                <button
                  type="button"
                  className="grd-btn py-2 d-flex align-items-center"
                  onClick={()=>{
                    setaddmore("addmore")
                    setShowAddUserModal(true)
                  }}
                >
                  <svg className="icon fs-20 me-1">
                    <use href="#icon_plus"></use>
                  </svg>
                  Create Account
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <thead>
              <tr className="tr-bg">
                <th style={{ padding: "12px 0" }}>Serial Number</th>
                <th>Employee Name</th>
                <th>Email/Username</th>
                <th>Last Login</th>
                {/* <th></th> */}
              </tr>
              </thead>
              <tbody>
              {searchedmembers.length || searchInput
                ? mapMembers(searchedmembers)
                : mapMembers(members)}
              </tbody>
            </table>
            { searchedmembers.length == 0 && members.length == 0 && !isLoading ? (
              <h2 className="  text-center">No Members found!</h2>
            ) : (
              ""
            )}
            { searchedmembers.length == 0 && searchInput ? (
              <h2 className="  text-center">No searched Members found!</h2>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
       <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}   centered>
      <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Title >
          <p className="fs-4 text-center">Add New {memberType} Account</p></Modal.Title>
        <Modal.Body className="p-4">
          <form onSubmit={handleAddUser} className="d-flex justify-content-center mt-2 flex-column">
          <select className="form-select mb-3" aria-label="Add More" value={addmore} onChange={(e)=>setaddmore(e.target.value)}>
            {/* <option value="">Choose</option> */}
            <option value="addmore">Add User</option>
            {memberdata?.map((item)=>(
            <option value={item?.id}>{item?.user_full_name}</option>
            ))}
          </select>
          {addmore== "addmore" && 
          
          <>
          <div className="input-box-no-border mb-3">
          {addNewUserDetails.name &&
            addNewUserDetails.name != "" && (
              <label htmlFor="name"> Name*</label>
            )}
          <input
            type="text"
            className="w-100"
            onChange={handleAddNewUserChange}
            id="name"
            value={addNewUserDetails.name}
            placeholder="Enter Name*"
          />
        </div>
        {addNewUserErrors.name && (
          <div className="text-danger mb-3">
            Please enter valid name 
          </div>
        )}
          <div className="input-box-no-border mb-3">
          {addNewUserDetails.email &&
            addNewUserDetails.email != "" && (
              <label htmlFor="email"> Email*</label>
            )}
          <input
            type="email"
            className="w-100"
            onChange={handleAddNewUserChange}
            id="email"
            value={addNewUserDetails.email}
            placeholder="Enter  Email*"
          />
        </div>
        {addNewUserErrors.email && (
          <div className="text-danger mb-3">
            Please enter valid email address
          </div>
        )}
          <div className="input-box-no-border mb-3">
          {addNewUserDetails.phone &&
            addNewUserDetails.phone != "" && (
              <label htmlFor="phone"> Phone *</label>
            )}
          <input
            type="number"
            className="w-100"
            onChange={handleAddNewUserChange}
            id="phone"
            value={addNewUserDetails.phone}
            placeholder="Enter  Phone*"
          />
        </div>
        {addNewUserErrors.phone && (
          <div className="text-danger mb-3">
            Please enter phone number
          </div>
        )}
        <div className="input-box-no-border mb-3">
          {addNewUserDetails.password &&
            addNewUserDetails.password != "" && (
              <label htmlFor="password"> Password*</label>
            )}
          <div className="position-relative">
          <input
            type={showPassword ? "text":"password"}
            className="w-100"
            onChange={handleAddNewUserChange}
            id="password"
            value={addNewUserDetails.password}
            placeholder="Enter Password*"
          />
           <button
           className="vision"
        type="button"
        onClick={() => setShowPassword(!showPassword)}
      >
       {!showPassword ? (
                      <svg className="icon">
                        <use href="#icon_vision_with_slash"></use>
                      </svg>
                    ) : (
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    )}
      </button>
          </div>
        </div>
        {addNewUserErrors.password && (
          <div className="text-danger mb-3">
            <p>Please enter strong password.</p>
            <small><i>**note: password should contain atleast 5 characters,1 uppercase letter & 1 special character</i></small> 
          </div>
        )}
          </>
          }
          
            <button
              disabled={isLoadingAdding}
             type="submit"
             className="align-self-end grd-btn"
            >
              {!isLoadingAdding ? "Submit" : <Loader />}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    <SuccessModal handleCloseSuccess={()=>setShowSuccessModal(false)} showSuccess={showSuccessModal} successMessagePopup={sucessMsg}/>

    </>
  );
}

export default MembersPage;
