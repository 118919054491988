import { React, useRef, useState } from "react";
import {  Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import Loader from "../components/Loader";

function Login() {
  const [loginDetails, setloginDetails] = useState({
    email: "",
    password: "",
    eyeShown: false,
  });
  const [error, seterror] = useState({
    status: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const emailInputRef = useRef();
  const passInputRef = useRef();

  async function handleLogin(e) {
    seterror({
      status: false,
      message: "",
    });
    e.preventDefault();
    setIsLoading(true);
    const email = emailInputRef.current.value;
    const pass = passInputRef.current.value;
    try {
      const cipherText = CryptoJS.AES.encrypt(
        pass,
        process.env.REACT_APP_SECRET
      );
      const data = {
        email: email,
        password: cipherText.toString(),
        app_type: "admin",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmuser/login`,
        data
      );
      if (response.status == 200) {
        setIsLoading(false);
        if (response.data.success) {
          localStorage.setItem("mtm_user_token", response?.data?.token);
          localStorage.setItem(
            "mtm_user_data",
            JSON.stringify(response?.data?.data)
          );
          navigate("/", { replace: true });
        }
      } else {
        setIsLoading(false);
        alert("Error");
        console.log("Error");
      }
    } catch (error) {
      seterror({
        status: true,
        message: error?.response?.data?.msg,
      });
      setIsLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <div className="signin-box">
        <Row className="h-100 w-100 g-0">
          <Col sm={5} className="signin-left">
            <img src={window.location.origin + "/images/left-img.png"}></img>
          </Col>
          <Col sm={7} className="signin-right">
            <div className="d-flex justify-content-center pb-4">
              <img
                src={window.location.origin + "/images/logo.png"}
                className="w-100"
                alt="...."
                style={{ maxWidth: "240px", maxHeight: "80px" }}
              />
            </div>
            <h2 className="text-center fw-700 fs-40">Admin</h2>
            <h2 className="fw-700 fs-35 pt-4">Log In</h2>
            <form onSubmit={handleLogin} className="my-5">
              <div className="mb-3">
                <label for="exampleFormControlInputEmail" class="form-label">
                  Email ID
                </label>
                <input
                  ref={emailInputRef}
                  type="email"
                  required
                  class="form-control form-control-lg input-shadow border-0"
                  id="exampleFormControlInputEmail"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-3">
                <label
                  for="exampleFormControlInputPassword"
                  class="form-label fs-18 fw-400"
                >
                  Password
                </label>
                <div className="position-relative">
                  <input
                    ref={passInputRef}
                    type={loginDetails.eyeShown ? "text" : "password"}
                    required
                    class="form-control form-control-lg input-shadow border-0"
                    id="exampleFormControlInputPassword"
                    placeholder="Enter your password"
                  />
                  <button
                    type="button"
                    className="vision"
                    onClick={() =>
                      setloginDetails({
                        ...loginDetails,
                        eyeShown: !loginDetails.eyeShown,
                      })
                    }
                  >
                    {loginDetails.eyeShown ? (
                      <svg className="icon">
                        <use href="#icon_vision_with_slash"></use>
                      </svg>
                    ) : (
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <div className="text-center text-danger fw-600 fs-20">
              {error.status && <>{error.message}</>}
            </div>
              <button
                type="submit"
                disabled={isLoading}
                className="my-5 grd-btn fs-20 fw-600 w-100 d-flex text-center"
              >
                {isLoading ? <Loader /> : "Login"}
              </button>
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Login;
