import ListGroup from "react-bootstrap/ListGroup";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import ApexChart from "../components/ApexChart";
import TypeChart from "../components/TypeChart";
import CountryChart from "../components/CountryChart";
import ShippingChart from "../components/ShippingChart";
import GMap from "../components/GMap";
import { getDashboardData, getFormattedDate } from "../utils/commonFn";
import Select from "react-select";
import { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ErrorModal from "../components/ErrorModal";
import { DateRangePicker } from "react-date-range";
import { Breadcrumb } from "antd";
// import TypeChart from './components/TypeChart';
function Dashboard() {
  const [initialdate, setInitialdate] = useState(true);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "year").toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [showdatepicker, setShowdatepicker] = useState(false);

  const [selectedDate, setSelectedDate] = useState(
    [
      dayjs(moment().subtract(1, "year")), // Set the "from" date 1 week before today
      dayjs(moment()), // Set the "to" date as today
    ].map((item) => {
      return getFormattedDate(item);
    })
  );
  const [dashboardRawData, setDashboardRawData] = useState();
  const [dashboardRawDataMap, setDashboardRawDataMap] = useState();
  const [statusPieList, setStatusPieList] = useState([]);
  const [containerTypeList, setContainerTypeList] = useState([]);
  const [countryContainerList, setCountryContainerList] = useState([]);
  const [shippingLineContainerList, setShippingLineContainerList] = useState(
    []
  );
  const [selectedContainerType, setSelectedContainerType] = useState();
  const [statuses, setStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const dateFormat = "DD/MM/YYYY";
  const navigate = useNavigate();
  const {customer_id,organization_name} = useParams()
  const handleDateChange = (dates) => {
    setSelectedContainerType();
    if (!dates) {
      setSelectedDate(
        [
          dayjs(moment().subtract(1, "week")), // Set the "from" date 1 week before today
          dayjs(moment()), // Set the "to" date as today
        ].map((item) => {
          return getFormattedDate(item);
        })
      );
    } else {
      setSelectedDate(
        dates?.map((item) => {
          return getFormattedDate(item);
        })
      );
    }
  };
  useEffect(() => {
    // console.log(selectedDate, "selectedDate");

    getDashboardData(
      [
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
      ],
      navigate,customer_id,
      {
        setDashboardRawData,setDashboardRawDataMap,
        setStatusPieList,
        setIsLoading,
        setShowErrModal,
        setError,
        setStatuses,
        setContainerTypeList,
        setCountryContainerList,
        setShippingLineContainerList,
      }
    );
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="col-12 py-3 d-flex justify-content-between">
           
            <Breadcrumb separator=">"  className="fs-22 fw-600 " items={[
               {
                title: (
                  <Link
                    className="text-decoration-none "
                    to={`/customers`}
                  >
                   
                   Customers
                  </Link>
                ),
                key: "Customers",
              },
               {
                title: (
                  <Link
                    className="text-decoration-none text-truncate"
                    style={{maxWidth:'350px',height:'max-content'}}
                    to={`/dashboard-chart/${customer_id}/${organization_name}`}
                  >
                  
                   {organization_name}
                  </Link>
                ),
                key: organization_name,
              },
            ]} />
           
          <div className="d-flex">
          <div className="me-4">
              <Select
                value={selectedContainerType}
                onChange={(option) => {
                  setSelectedContainerType(option);
                  getDashboardData(
                    [
                      moment(startDate).format("YYYY-MM-DD"),
                      moment(endDate).format("YYYY-MM-DD"),
                    ],
                    navigate,customer_id,
                    {
                      setDashboardRawDataMap,
                      setDashboardRawData,
                      setStatusPieList,
                      setIsLoading,
                      setShowErrModal,
                      setError,
                      setStatuses,
                      setContainerTypeList,
                      setCountryContainerList,
                      setShippingLineContainerList,
                    },
                    option
                  );
                }}
                placeholder="Container Type"
                aria-label="Container Type"
                isClearable={true}
                options={containerTypeList?.map((item) => ({
                  label: item?.container_type,
                  value: item?.container_type,
                }))}
              />
            </div>

            <div className="mb-20 mt-20 w-45">
              <input
                className="input-box input-date gray"
                name="from_date"
                placeholder={
                  !initialdate
                    ? `${moment(startDate).format(dateFormat)}-${moment(
                        endDate
                      ).format(dateFormat)}`
                    : "Select Date Range"
                }
                onClick={() => {
                  showdatepicker
                    ? setShowdatepicker(false)
                    : setShowdatepicker(true);
                }}
                readOnly
              />
              {showdatepicker && (
                <div className="date-picker-body">
                  <div className="form-card" style={{ paddingTop: "20px" }}>
                    <DateRangePicker
                      ranges={[
                        {
                          startDate,
                          endDate,
                          key: "selection",
                          color: "#0E2181",
                          rangeColors: "#303f8a",
                        },
                      ]}
                      onChange={(e) => {
                        setInitialdate(false);
                        setStartDate(e?.selection?.startDate);
                        setEndDate(e?.selection?.endDate);
                      }}
                    />
                    <div>
                      <button
                        style={{ marginLeft: "10px" }}
                        className="button   "
                        type="button"
                        onClick={() => setShowdatepicker(false)}
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginLeft: "30px" }}
                        className="button normal-btn"
                        onClick={() => {
                          setShowdatepicker(false);
                          getDashboardData(
                            [
                              moment(startDate).format("YYYY-MM-DD"),
                              moment(endDate).format("YYYY-MM-DD"),
                            ],
                            navigate,customer_id,
                            {
                              setDashboardRawDataMap,
                              setDashboardRawData,
                              setStatusPieList,
                              setIsLoading,
                              setShowErrModal,
                              setError,
                              setStatuses,
                              setContainerTypeList,
                              setCountryContainerList,
                              setShippingLineContainerList,
                            }
                          );
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          </Col>
        </Row>
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-3 g-3 pb-4">
          <div className="col " style={{ height: "max-content" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_origin"></use>
                    </svg>
                  </div>
                  <p>Total Containers At Origin</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">
                  {
                    statusPieList?.find(
                      (item) => item?.status_name == "At port of loading"
                    )?.total_container
                  }
                  &nbsp;
                </h4>
              </div>
            </div>
          </div>
          <div className="col " style={{ height: "max-content" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_transit"></use>
                    </svg>
                  </div>
                  <p>Total Containers In Transit</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">
                  {
                    statusPieList?.find(
                      (item) => item?.status_name == "Ocean in transit"
                    )?.total_container
                  }
                  &nbsp;
                </h4>
              </div>
            </div>
          </div>
          <div className="col " style={{ height: "max-content" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-22 text-white">
                      <use href="#icon_container"></use>
                    </svg>
                  </div>
                  <p>Total Containers Delivered</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">
                  {
                    statusPieList?.find(
                      (item) => item?.status_name == "Delivered"
                    )?.total_container
                  }
                  &nbsp;
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-2 row-cols-md-2 row-cols-lg-2 g-3 pb-4">
          <div className="col">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22">Status</h6>
              <ApexChart statusPieList={statusPieList} />
            </div>
          </div>
          <div className="col" style={{ minHeight: "350px" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22">Container Type</h6>
              <TypeChart containerTypeList={containerTypeList} />
            </div>
          </div>
          <div className="col" style={{ minHeight: "350px" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22">Container By Country</h6>
              <CountryChart countryContainerList={countryContainerList} />
            </div>
          </div>
          <div className="col" style={{ minHeight: "350px" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22">Shipping line vs Container</h6>
              <ShippingChart
                shippingLineContainerList={shippingLineContainerList}
              />
            </div>
          </div>
        </div>
        <Row className="mb-4">
          <Col sm={12}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22 pb-3">Global Map</h6>
              <div>
                  Marker colors :
                    <span className="mx-2">🟡 Yellow = Origin/Port of origin</span>
  <span className="mx-2">🔴 Red = Trans Shipment/Current Position</span>
  <span className="mx-2">🟢 Green = Destination/Port of Discharge</span>

                    
                  </div>
              {/* {isLoaded && ( */}
                <GMap
                  // loadError={loadError}
                  dashboardRawData={dashboardRawDataMap}
                />
              {/* )} */}
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} className="normal-tbl">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22 pb-3">Arrival To Warehouse</h6>
              <table>
                <tr className="tr-bg">
                  <th>Booking Number</th>
                  <th>Total Containers</th>
                  <th>POL</th>
                  <th>POD</th>
                  <th>Pedimento</th>
                  <th>Vendors</th>
                </tr>
                {dashboardRawData?.map((book) => (
                  <tr>
                    <td>{book?.booking_id}</td>
                    <td>{book?.total_container}</td>
                    <td>{book?.port_of_loading}</td>
                    <td>{book?.port_of_discharge}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </table>
            </div>
          </Col>
        </Row>
      </Container>

      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default Dashboard;
