import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";
import axios from "axios";
import Loader from "./Loader";
import moment from "moment";
import { handleLogOut } from "../utils/commonFn";

function NavBar() {
  const [greeting, setGreeting] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const updateGreeting = () => {
    const currentTime = moment();
    const currentHour = currentTime.hour();
    let newGreeting = "";
    if (currentHour >= 5 && currentHour < 12) {
      newGreeting = "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      newGreeting = "Good Afternoon";
    } else {
      newGreeting = "Good Evening";
    }
    // You can replace 'name' with the actual name variable from your state or props
    newGreeting +=
      " " + JSON.parse(localStorage.getItem("mtm_user_data"))?.user_full_name;
    setGreeting(newGreeting);
  };

  

  useEffect(() => {
    updateGreeting(); // Initial update when the component mounts
  }, []);
  return (
    <>
      <Navbar expand="lg" style={{ zIndex: 100 }}>
        <Container>
          <Navbar.Brand onClick={() => navigate("/")} className="d-flex">
            <img
              src={window.location.origin + "/images/logo.png"}
              style={{ width: "140px", height: "40px", maxHeight: "52px" }}
            ></img>
          </Navbar.Brand>
          <div className="fw-600 mt-2 fs-18" style={{ fontSize: "18px" }}>
            {greeting}
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Form className="d-flex ms-auto">
              <button
                disabled={isLoading}
                onClick={()=>handleLogOut(setIsLoading,navigate)}
                type="button"
                className="logout-btn grd-btn py-2"
              >
                {isLoading ? <Loader /> : "Logout"}
              </button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
