import React from 'react'
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import { handleLogOut } from '../utils/commonFn';

function AsideBar() {
    const moreLink = (env, id) => {
        if (!env.target.matches('.active')) {
            document.querySelectorAll('.nav-toggle').forEach((e)=>{
                e.classList.remove('active');
            });
            document.querySelectorAll('.navmore-link').forEach((e)=>{
                e.classList.remove('active');
            });
            env.target.classList.add('active');
            document.getElementById(id).classList.add('active');
        }
        else{
            env.target.classList.remove('active');
            document.getElementById(id).classList.remove('active');  
        }
    }
    const menuToggle = () => {
        document.body.classList.toggle('shrink-menu');
    }
    const navigate = useNavigate()
  return (
    <>
    <div className='aside-new' id="asideBar">
        <div className='d-flex px-3 pt-2'>
            <button type="button" className='ms-auto menu-btn' onClick={()=>menuToggle()}>
                <svg className="icon">
                    <use href="#icon_menu"></use>
                </svg>
            </button>
        </div>
        <ul>
            <li>
                <NavLink className="nav-link" to="/">
                    <span>
                        <svg className="icon">
                            <use href="#icon_dashboard"></use>
                        </svg>
                    </span>
                    <strong>Dashboard</strong>
                </NavLink>
            </li>
            <li>
                <NavLink className="nav-link" to="/customers">
                    <span>
                        <svg className="icon">
                            <use href="#icon_customer"></use>
                        </svg>
                    </span>
                    <strong>Customers</strong>
                </NavLink>
            </li>
            <li>
                <NavLink className="nav-link" to="/operations">
                    <span>
                        <svg className="icon">
                            <use href="#icon_setting"></use>
                        </svg>
                    </span>
                    <strong>Operations</strong>
                </NavLink>
            </li>
            <li>
                <NavLink className="nav-link" to="/sales">
                    <span>
                        <svg className="icon">
                            <use href="#icon_sales"></use>
                        </svg>
                    </span>
                    <strong>Sales</strong>
                </NavLink>
            </li>
            <li>
                <NavLink className="nav-link" to="/pricing">
                    <span>
                        <svg className="icon">
                            <use href="#icon_pricing"></use>
                        </svg>
                    </span>
                    <strong>Pricing</strong>
                </NavLink>
            </li>
            {/* <li>
                <NavLink className="nav-link" to="/">
                    <span>
                        <svg className="icon">
                            <use href="#icon_admin"></use>
                        </svg>
                    </span>
                    <strong>Admin Roles</strong>
                </NavLink>
            </li> */}
            <li>
                <div className="nav-link nav-toggle" onClick={(e)=>moreLink(e,'more1')}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_profile"></use>
                        </svg>
                    </span>
                    <strong>Profile Setting</strong>
                </div>
                <div className="navmore-link" id="more1">
                    <NavLink to="/profile">Profile</NavLink>
                    <NavLink to="/security">Security</NavLink>
                </div>
            </li>
            <li>
                <NavLink className="nav-link" to={'/login'} onClick={()=>handleLogOut(()=>{},navigate)}>
                    <span>
                        <svg className="icon">
                            <use href="#icon_logout"></use>
                        </svg>
                    </span>
                    <strong>Logout</strong>
                </NavLink>
            </li>
        </ul>
    </div>
    </>
  )
}

export default AsideBar