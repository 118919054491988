import axios from "axios";
import { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import ErrorModal from "../components/ErrorModal";
import Loader from "../components/Loader";
import { SuccessModal } from "../components/SuccessModal";

function ProfileSettings() {
  const mtm_user_data = JSON.parse(localStorage.getItem("mtm_user_data"));
    const [formdata, setFormData] = useState({
      user_email: mtm_user_data?.email,
      user_phone: mtm_user_data?.phone,
      user_firstname: mtm_user_data?.user_full_name?.split(" ")[0],
      user_lastname: mtm_user_data?.user_full_name?.split(" ")[1],
      id: mtm_user_data?.id,
      });
const [isLoading, setIsLoading] = useState(false)
    const [editCancel, setEditCancel] = useState(false);
      const [error, setError] = useState()
      const [showErrModal, setShowErrModal] = useState(false)
      const [showSuccessModal, setShowSuccessModal] = useState(false)
      const [sucessMsg, setSucessMsg] = useState("")
      const handleFormData = (e) => {
        setFormData({
          ...formdata,
          [e.target.id]: e.target.value,
        });
      };
      const navigate = useNavigate()
      async function handleProfileUpdate() {
        setIsLoading(true);
        try {
          let response = await axios({
            url: `${process.env.REACT_APP_BASE_URL}/mtmdashboard/profile/update`,
            method: "POST",
            headers: {
              jwt_token: localStorage.getItem("mtm_user_token"),
            },
            data: formdata,
          });
          if (response.status == 200 && !response.data.error) {
            localStorage.setItem("mtm_user_data", JSON.stringify(response.data.user));
            setSucessMsg("Profile Updated Successfully")
            setShowSuccessModal(true)
            setTimeout(() => {
              setSucessMsg('')
              setShowSuccessModal(false)
            }, 2000);
          }else{
            setError({title:"Error",err:response.data.message})
            setShowErrModal(true)
          }
        } catch (error) {
          if (error.response.status == 401) {
            localStorage.removeItem("mtm_user_token");
            localStorage.removeItem("mtm_user_data");
            navigate("/login");
            return;
          }
        } finally {
          setIsLoading(false);
          setEditCancel(!editCancel)
        }
      }

  return (
    <>
    <Container fluid>
      <Row>
        <Col sm={12}>
          <h2 className='fw-700 fs-30 pt-4'>Profile Setting</h2>
        </Col>
      </Row>
      <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            <div className="row">
              <div className="col-auto ms-auto">
                {editCancel ? (
                  <button
                    className="border-btn"
                    onClick={() => setEditCancel(!editCancel)}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    className="border-btn"
                    onClick={() => setEditCancel(!editCancel)}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
            <div className="row pb-4 g-4">
              <div className="col-sm-6">
                <label htmlFor="first_name" className="form-label fw-500 fs-18">
                  First Name
                </label>
                <input
                  type="text"
                  value={formdata?.user_firstname}
                  disabled={!editCancel}
                  onChange={handleFormData}
                  placeholder="Enter First Name"
                  className="form-control"
                  id="user_firstname"
                />
              </div>
              <div className="col-sm-6">
                <label htmlFor="last_name" className="form-label fw-500 fs-18">
                  Last Name
                </label>
                <input
                  type="text"
                  value={formdata?.user_lastname}
                  disabled={!editCancel}
                  onChange={handleFormData}
                  placeholder="Enter Last Name"
                  className="form-control"
                  id="user_lastname"
                />
              </div>
              <div className="col-sm-6">
                <label className="form-label fw-500 fs-18">Phone Number</label>
                <input
                type="text"
                className="form-control"
                placeholder="Enter Phone Number"
                value={formdata?.user_phone}
                disabled={!editCancel}
                onChange={handleFormData}
                id="user_phone"
                />
              </div>
              <div className="col-sm-6">
                <label className="form-label fw-500 fs-18">Email</label>
                <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
                value={formdata?.user_email}
                disabled
                id="user_email"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                {editCancel && (
                  <div className="pb-3">
                    <button
                    disabled={isLoading}
                      typeof="button"
                      className="normal-btn px-4 rounded-pill me-4"
                      onClick={() => handleProfileUpdate()}
                    >
                      {isLoading? <Loader/> : "Save"}
                    </button>
                    <button
                      typeof="button"
                      className="normal-btn px-4 rounded-pill"
                      onClick={()=>{
                        setFormData({
                          user_email: mtm_user_data?.email,
                          user_phone: mtm_user_data?.phone,
                          user_firstname: mtm_user_data?.user_full_name?.split(" ")[0],
                          user_lastname: mtm_user_data?.user_full_name?.split(" ")[1],
                          id: mtm_user_data?.id,
                          })
                          setEditCancel(!editCancel)
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
    </Container>
    <ErrorModal error={error} setShowErrModal={setShowErrModal} showErrModal={showErrModal} />
    <SuccessModal handleCloseSuccess={()=>setShowSuccessModal(false)} showSuccess={showSuccessModal} successMessagePopup={sucessMsg}/>
    </>
  )
}

export default ProfileSettings